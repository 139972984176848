/*jshint esversion: 6 */

var template = document.body.dataset.intendedTemplate;

var isHistoryAvailable = (typeof history.pushState === 'undefined') ? false : true;
var page = document.querySelector('#page');

var iso = null, isoblocs = [];


// ------------------------------------------------------------------------------------------------ ajax
setAjaxEvents = function() {
  var ajaxlinks = document.querySelectorAll('.ajax');
  ajaxlinks.forEach(link => {
      link.addEventListener('click', function(e){
        navigacion.classList.remove('opened');
        e.preventDefault();
           
        var url = link.href;
        if(isHistoryAvailable){
          history.pushState({'type':'page'}, 'null', url);
        }
        loadPage(url,'page');
      });
  });
};


// ------------------------------------------------------------------------------------------------ Iso + filters
showProjects = function(blocs){
  document.body.classList.add('preloaded');
  document.body.classList.remove('loading');
  // console.log(blocs);
  blocs.forEach((bloc, idx) => {    
      setTimeout(() => {
        bloc.element.classList.add('revealed');
      }, 100 * idx);    
  });
};

do_iso = function(filters){
  iso = new Isotope( page, {
    itemSelector: '.bloc',
    stamp: '#hat, #cap',
    filter:filters,
    initLayout: false
  });
  iso.on( 'arrangeComplete', function( blocs ) {
    isoblocs = blocs.slice().reverse();
    showProjects(blocs);
  });

  iso.arrange();
};



// ------------------------------------------------------------------------------------------------ Home
home = function(url){

  setAjaxEvents();
  var hash = window.location.hash;

  if(hash || url && document.location.pathname!='/'){		
    if (hash) selector = hash.replace('#', '.');
    else selector = url.replace('/#', '.');
    filters = selector;
  } else {
    filters = '*';
  }
  
  imagesLoaded( page, function() {
    do_iso(filters);
  });
  
};

if(template == "home"){
  home();
}


// ------------------------------------------------------------------------------------------------ About
about = function(url){

  setAjaxEvents();
  var hash = window.location.hash;

  if(hash || url && document.location.pathname!='/'){		
    if (hash) selector = hash.replace('#', '.');
    else selector = url.replace('/#', '.');
    filters = selector;
  } else {
    filters = '*';
  }
  
  document.body.classList.remove('loading');
  
};

if(template == "about"){
  about();
}


// ------------------------------------------------------------------------------------------------ Mobile menu
var menuhandler = document.querySelector('#menuhandler'),
    navigacion = document.querySelector('#navigacion');
    
menuhandler.addEventListener('click', function(){
  navigacion.classList.toggle("opened");
});


// ------------------------------------------------------------------------------------------------ Filtering
navigacion.addEventListener('click', function(event){
  template = document.body.dataset.intendedTemplate;
  if(event.target.matches('.filter')){
    event.preventDefault();
    navigacion.classList.toggle('opened');
    
    var href = event.target.href;
    var filter = event.target.dataset.filter;    
    
    if(template != "home"){
      loadPage(href, 'home');
    } else {
      var selector = filter + ', #cap';
      if(iso){
        iso.destroy();
        do_iso(filter);
      }
    }
      
    if(isHistoryAvailable){
      history.pushState({'type':'home'}, null, href);
    }
  }
});



loadPage = function(url, type){
  window.scroll({top: 0, left: 0, behavior: 'smooth'});
  document.body.classList.remove('preloaded');
  document.body.classList.add('loading');
  fetch(url)
    .then(function(response) {
      return response.text();
    })
    .then(html => {
      timout = 150;
      if(isoblocs.length && type == "page"){
        timout = (isoblocs.length) * 40 + 150;
        isoblocs.forEach((bloc, idx) => {      
          setTimeout(() => {
            bloc.element.classList.remove('revealed');
          }, 40 * idx);        
        });
      } 
      return new Promise(resolve => setTimeout(() => {
        resolve(html);
      }, timout));
    })
    .then(function(html) {
      if(iso){
        iso.destroy();
      }    
      // Initialize the DOM parser
      var parser = new DOMParser();
      var doc = parser.parseFromString(html, "text/html");
      var tpl = doc.body.dataset.intendedTemplate;
      document.body.dataset.intendedTemplate = tpl;
      var content = doc.querySelector('section').innerHTML;

      // ogp & co
      var metas = ['name="description"','name="keywords"','property="og:url"','property="og:type"','property="og:title"','property="og:description"','property="og:site_name"','property="og:locale"','property="og:image"','property="og:image:width"','property="og:image:height"','name="twitter:card"','name="twitter:title"','name="twitter:description"','name="twitter:image"'];
      metas.forEach(meta => {
        var m0 = doc.querySelector("[" + meta + "]");
        var m1 = document.querySelector("[" + meta + "]");
        if(m0 && m1) m1.content = m0.content;
      });
      //  title
      document.querySelector('title').textContent = doc.querySelector('title').textContent;

      // content
      var section = document.querySelector('section');
      section.innerHTML = content;
      section.id = type + "_section";

      if(type=='page'){
        project();
      } else {
        home(url);
      }
    });
};

window.onpopstate = function(event){
  if(event.state === null ){
    loadPage(document.location.pathname, 'home');
  } else {
    if(event.state.type === 'home' ){
      loadPage(document.location.pathname, 'home');
    } else {
      loadPage(document.location.pathname,'page');
    }
  }
};

// ------------------------------------------------------------------------------------------------ Project Images 

project = function(){
  setAjaxEvents();
  var section = document.querySelector('section');
  section.classList.add('visible');
  
  document.body.classList.add('preloaded');
  
  var imatges = document.querySelectorAll('.imatge');	
  imagesLoaded( section, function() {
    document.body.classList.remove('loading');
    imatges.forEach((imatge, idx) => {
      setTimeout(() => {
        imatge.classList.add('revealed');
      }, 100 * idx);
    });
  });
};

if(template == "project"){
  project();
}


// ------------------------------------------------------------------------------------------------ Video players 

const players = Array.from(document.querySelectorAll('.js-player')).map(p => new Plyr(p));

